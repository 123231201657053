<template>
  <div style="position: relative;" ref="rootDiv" >

      <!--  좌측 상단 라이브버튼    -->
      <div id="info-live-root" v-if="isShowLiveText" class="livePopMark" style="display :block">
        <img src="@/assets/images/icon_svg/ic_live_mark.svg"/>
      </div>

      <!-- 우측상단 사진캡처 버튼 -->
      <div ref="vodScreenShot" class="vodDown flex"  v-show="isPlayStatus && (isVod ? true : isLiveButtonShow )" :style="{'right' : captureRight + 'px'}" @click="onVideoCapture">
        <img src="@/assets/images/icon_svg/ic_screen_shot.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>

      <!-- 우측상단 전체화면 버튼 -->
      <div ref="popupFullScreen" class="vodDown flex" v-if="isLiveButtonShow && isPlayStatus" :style="{'right' : expandRight + 'px'}" @click="toggleFullScreen">
        <img v-if="!isFullScreen" src="@/assets/images/icon_svg/ic_full_bk.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
        <img v-if="isFullScreen" src="@/assets/images/icon_svg/ic_full_end.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>

      <!-- 라이브 우측하단 전체화면 버튼(팝업으로 띄움) -->
      <div v-if="isShowFullScreen && playerId != undefined && !isVod" @click="openFullScreenModal" class="right-bottom-fullscreen">
        <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
      </div>

      <!-- 다운로드 버튼 -->
      <div ref="vodDownload" class="vodDown flex"  v-show="isVod && isDownload && isPlayStatus"  :style="{'right' : downloadRight + 'px'}" @click="onVideoDownload">
        <img src="@/assets/images/icon_svg/ic_download_vod.svg" style="width: 2rem; height: 2rem;"/>
      </div>

      <!-- 삭제 버튼 -->
      <div class="vodDown flex" v-if="isVod && isDeleteButtonShow && isDelete && isPlayStatus" :style="{'right' : deleteRight + 'px'}" @click="onVideoDelete">
        <img src="@/assets/images/icon_svg/ic_delete_vod.svg" style="width: 1.8rem; height: 1.8rem;"/>
      </div>

      <!-- 오른쪽 하단 스피커버튼 -->
      <!-- 드론은 소리x -->
      <div v-if="isShowVolum&& !isVod && deviceType !== 'Drone' && deviceType !== 'StationDrone'" @click="isSoundToggle" class="soundBox" >
        <img class="speekerSize" v-if="!muted" src="@/assets/images/icon_svg/ic_speaker_on.png" alt="icon" />
        <img class="speekerSize"  v-else src="@/assets/images/icon_svg/ic_speaker_off.png" alt="icon" />
      </div>

      <!-- AI 선택 버튼 (유저 라이센스에 따라 열림) -->
      <div v-if="false" ref="aiSelect" v-show="( this.$router.currentRoute.name=='vodPlay' || isAiMarkShow) && isPlayStatus" class="vod-ai-mark" id="ai-mark-root">
        <div class="vod-ai-btn">
          <input type="radio" v-model="selectAiDetecing" name="detecting" value ="object">
          <label>객체 감지</label>
          <input type="radio" v-model="selectAiDetecing" name="detecting" value ="fire" >
          <label>불 감지</label>
          <input type="radio" v-model="selectAiDetecing" name="detecting" :value ="null" >
          <label style="margin-right: 0px;">감지 해제</label>
        </div>
      </div>

      <div class="video-player video-player-box vjs-big-play-centered">
        <video
            ref="videoPlayer"
            class="video-js"
            id="videoPlayer"
            :controls="isControls"
            :autoplay="isAuto">
        </video>
      </div>
  </div>
</template>

<style scoped>
.video-player .video-js video {
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}
.play-3-view > .video-player .video-js {height: auto;padding-top: 56.25%;}
.play-4-view > .video-player-box .video-js {height: auto;padding-top: 50.25%;}
.play-9-view > .video-player-box .video-js {height: auto;padding-top: 50.25%;}
</style>

<script>
import { fetchDeleteVod, fetchAiVodDownloadUrl, fetchVodDownloadUrl} from "@/api/contents";
fetchVodDownloadUrl
import { fetchChannelCheckStream } from "@/api/channel";
import moment from "moment";
import {playerPopOpen2} from "@/util/popup";


export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    isVod: {
      type: Boolean,
      default: true,
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    playerId: {
      type:String,
      default: null,
    },
    contentsId:{
      type:String,
      default: null,
    },
    videoType :{ //추후 ai디텍팅시에 디텍팅 타입을 받으면 된다.
      type : String,
      default : "orginal"
    },
    isAuto : { // 영상자동실행 vod는 false, live의경우 true
      type : Boolean,
      default : false,
    },
    isControls : { // 하단 컨트롤바 vod:true, live:false
      type : Boolean,
      default : true,
    },
    isLiveButtonShow: { // 왼쪽상단 live버튼
      type : Boolean,
      default : false,
    },
    isDeleteButtonShow: {
      type : Boolean,
      default : true,
    },
    isAiMarkShow:{
      type : Boolean,
      default : false,
    },

    deviceType: {
      type: String,
      default: null
    },
    // 현재 streamId(playerId)와, 선택한 streamId를 비교해서 소리를 들리게할지말지 결정
    selectStreamId: {
      type : String,
    },
    channelIndex: {
      type: Number,
      default: null
    },

    isShowVolum: {
      type: Boolean,
      default: true
    },
    isShowFullScreen: {
      type: Boolean,
      default:true
    },
    isShowLiveText: {
      type: Boolean,
      default:true
    },

    mediaKey: {
      type: String,
      default: null
    }
    //isai-mark  샤용유무 값 받아서 표출할지말지 결정
    //ai this.$emit 을 사용해서 값 넘겨주기. (ai-object , ai-fire, ai-clear)
    //새창보기부분 표출(map, 현장정보) id값 받아서 플레이어 자식에 추가하기.
  },
  data() {
    return {
      hls: null,
      isLoadedmetadata : false,
      interval : null,
      watingInterval: null,
      isWaitingRunning: false,
      reloadInterval: null,
      reloadCount: 0,
      liveLoadError : false,
      player: null,
      muted: true,

      playerOptions: {
        // 기본 설정
        disablePictureInPicture: true, // Picture-in-Picture(작은 화면 모드) 기능 비활성화 (default: false)
        playbackRates: [0.5, 1.0, 2.0, 4.0], // 재생 속도 옵션 (VOD에서 사용)
        muted: true, // 비디오가 처음 재생될 때 음소거 상태로 시작 (default: false)
        volume: 0.5, // 초기 볼륨 설정 (0.0 ~ 1.0 사이 값, default: 1.0)
        autoplay: this.isAuto, // 자동 재생 여부 (true/false)
        language: "ko", // UI 언어 설정 (ISO 언어 코드, 예: "en", "ko")
        errorDisplay: false, // 재생 오류 발생 시 오류 메시지 표시 여부 (default: true)
        controls: this.isControls, // 컨트롤바 표시 여부 (true/false)
        crossOrigin : "anonymous", // cors

        controlBar: {
          // 컨트롤바 세부 설정
          playToggle: true, // 재생/일시정지 버튼 표시 (default: true)
          currentTimeDisplay: true, // 현재 재생 시간 표시 (default: true)
          timeDivider: true, // 현재 시간과 총 시간 사이 구분선 표시 (default: true)
          durationDisplay: true, // 총 비디오 길이 표시 (default: true)
          remainingTimeDisplay: false, // 남은 시간 표시 (default: true)
          progressControl: true, // 재생 바(progress bar) 표시 (default: true)
          liveDisplay: true, // 라이브 스트림 아이콘 표시 (default: true, 라이브 스트림 전용) 하지만 라이브는 컨트롤바 없긴함
          seekToLive: true, // 라이브 스트림에서 "Live" 버튼 표시 (default: false, 라이브 스트림 전용)
          playbackRateMenuButton: this.isVod, // 재생 속도 메뉴 표시 (VOD 전용, true/false)
          audioTrackButton: true, // 오디오 트랙 변경 버튼 표시 (default: false)
          fullscreenToggle: true, // 전체화면 버튼 표시 (default: true)
        },

        hls: true, // HLS 스트림 지원 활성화 (true/false, 기본 Video.js HLS 지원)
        downloadFile: true, // 다운로드 버튼 활성화 (추가 플러그인 필요, 기본 Video.js에는 없음)
        bigPlayButton: true, // 중앙의 큰 재생 버튼 표시 (default: true)
      },

      isFullScreen : false,
      selectAiDetecing : null,
      isPlayStatus : false,
      oldCurrentTimeError : 0,
      watingCount : 0,

      buttonDefaultRight: 10,
      buttonMargin : 16,
      buttonSize : 32,

      isDelete : !this.isUser,
      isDownload : this.isVod,
      isExpand : !this.isVod,

      playerAddDom : [],
    };
  },
  computed : {
    captureRight: function () {
      var right = 0;
      if(this.isVod) {
        right = this.buttonDefaultRight;
        if(this.isDelete && this.isDeleteButtonShow) {
          right = right + this.buttonMargin + this.buttonSize;
        }
        if(this.isDownload) {
          right = right + this.buttonMargin + this.buttonSize;
        }

      } else {
        right = this.buttonDefaultRight;
        if(this.isExpand) {
          right = right + this.buttonMargin + this.buttonSize;
        }
      }

      if(this.isFullScreen) {
        right = 0;
      }

      if(right == 0){
        right = this.buttonDefaultRight;
      }
      return right;
    },
    deleteRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    downloadRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = right + this.deleteRight;
        right = right + this.buttonMargin + this.buttonSize;
      }
      if(right == 0) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    expandRight: function () {
      var right = 0;
      if(this.isExpand) {
        right = this.buttonDefaultRight;
      }
      return right;
    }
  },
  mounted() {
    const videoElement = this.$refs.videoPlayer
    this.player = window.videojs(videoElement,this.playerOptions);

    /**
       * VideoJS lifeCycle
       * vod: loadeddata -> ready -> play, timeupdate(interval로 도는중)
       * live: loadeddata -> ready -> play -> waiting -> canplay
       */
      this.player.on('error',this.playerError)
      this.player.on("ready", this.playerReadied)
      this.player.on("timeupdate", this.onPlayerTimeupdate)
      this.player.on("loadeddata", this.onPlayerLoadeddata)
      this.player.on("waiting",this.onPlayerWaiting);
      this.player.on("canplay",this.onCanPlay);
      this.player.on("playing",this.onPlaying);
      this.player.on('play', this.onPlayerPlay);
      this.player.on('pause',this.onPlayerPause);


    if(!this.isVod) {
      console.log("this is live!")
      this.interval = setInterval(() => {
        this.onPlayerCheckStatus();
      }, 3000);
    }
    addEventListener("fullscreenchange", this.changeFullScreen);
  },

  beforeDestroy() {
    this.init()
    clearInterval(this.interval);
    this.interval = null
    this.player.dispose()
  },
  watch: {
    selectAiDetecing(value) {
      this.$emit("onUpdateAiDetecting",value);//현재 동영상 시간, contentId, aiDetecting type 넘기기.
    },
    isUser(value) {
      this.isDelete = !value;
    },
    src() {
      this.isPlayStatus = false;
      this.getVideo();
    },
    selectStreamId: function(newValue){
      if(this.playerId != newValue ) {
        this.mute()
      } else {
        this.unMute()
      }
    },
  },
  methods: {
    init() {
      console.log("초기화")
      this.oldCurrentTimeError = 0
      this.watingCount = 0
      this.reloadCount = 0
      this.isWaitingRunning = false;

      // waitingInterval 이 clear해줘도 null이 안되서 clearInterval 후, null로 초기화
      clearInterval(this.watingInterval)
      this.watingInterval = null
      console.log("waiting interval",this.watingInterval)

      clearInterval(this.reloadInterval)
      this.reloadInterval = null
      console.log("reload interval",this.reloadInterval)
    },

    openFullScreenModal() {
      playerPopOpen2("live", this.playerId,this.channelIndex);
    },
    isSoundToggle(){
      this.muted = !this.muted
      this.player.muted(this.muted)
      this.$emit("toggleSound",this.playerId)
    },
    changeMute(mute){
      this.muted = mute
      this.player.muted(this.muted)
    },
    mute(){
      this.changeMute(true)
    },
    unMute(){
      this.changeMute(false)
    },

    /**
     * 와우자 스트리밍 체크
     * bytesIn가 없을시 error++,
     * error > 3이상일시 스트림채널 삭제
     */
    onPlayerCheckStatus(){
      // vod 영상의 경우 상태체크 할 필요가 없음
      if(this.isVod){ return }
      //TODO CCTV
      if(this.playerId == "CCTV")
        return
      console.log("onPlayerCheckStatus",this.playerId)
      fetchChannelCheckStream(this.playerId).then((res) => {
            if (res.data.result !== 0) { return }
            if(res.data.data.bytesIn == 0 || res.data.data.bytesIn === null) { // 데이터를 안주고 있는경우 에러카운트 +1
              this.oldCurrentTimeError++;
            } else {
              this.oldCurrentTimeError = 0;
            }
            if( this.oldCurrentTimeError > 3){ // 에러카운트가 3이상일 경우 채널에서 삭제
              this.onPlayerRemove();
            }
          })
    },


    async onVideoDownload(){
      // accountID 가 없는경우 리턴
      if(this.contentsId === null || !confirm(this.$t("alert-message-download-contents-player-message"))) {return}

      let res = null
      if(!this.isAiMarkShow) {
        res = await fetchVodDownloadUrl(this.contentsId) // vod 다운로드
      } else {
        res = await fetchAiVodDownloadUrl(this.mediaKey, this.$route.params.accountId, this.contentsId); // aiVod 다운로드
      }

      const blob = new Blob([res.data]);
      const fileObjectUrl = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = fileObjectUrl;
      downloadLink.style.display = "none";

      downloadLink.download =`${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.mp4`; // 파일명
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      window.URL.revokeObjectURL(fileObjectUrl);
    },


    onVideoCapture(){
      // if (!confirm(this.$t("alert-message-download-contents-capture-message")) ) {
      //   return;
      // }
      let videoElement = this.$refs.videoPlayer;
      let canvas = document.createElement("canvas");
      canvas.crossOrigin = "anonymous";
      let ctx = canvas.getContext('2d');

      canvas.width = 640;
      canvas.height = 360;

      ctx.fillRect(0,0,canvas.width,canvas.height);
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height );
      ctx.crossOrigin = "anonymous";

      var link = document.createElement('a');
      link.download = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
    },
    onVideoDelete(){
      if (!confirm(this.$t("alert-message-delete-contents-message")) ) {
        return;
      }
      fetchDeleteVod(this.contentsId).then((res) => {
        if (res.data.result == 0) {
          this.$emit("contents-delete", this.contentsId)
        }
      });
    },
    toggleFullScreen(){
      this.$emit("toggleFullScreen", !this.isFullScreen);
    },
    changeFullScreen(){
      this.isFullScreen = !this.isFullScreen
    },

    getVideo() {
      if(this.player != null && this.player.src() != this.src) {
        this.player.src({
          src: this.src,
          type: 'application/x-mpegURL',
          withCredentials: false
        });
      }
    },
    onPlayerTimeupdate() {
      if(this.player == null) {
        return;
      }
      this.$emit("onTimeUpdate", this.player.currentTime(),this.contentsId);
    },
    onPlayerRemove(){
      this.$emit('playerRemoveId',this.playerId)
    },


    reloadPlayer(){
      if(this.reloadCount > 5) {
        console.log("reloadCount > 3")
        this.onPlayerRemove();
      }

      if(this.liveLoadError == true && this.isVod == false) {
        this.reloadCount++;
        this.getVideo();
        this.player.load()
        this.player.play()
        this.isPlayStatus = false;
      }
    },

    /**
     * waiting은
     */
    runWaitingInterval() {
      // 기존에 실행중인게 있다면 리턴
      this.watingInterval = setInterval(() => {
        console.log("loading...")
        if(this.watingCount >= 3) {
          this.player.load()
          this.player.play()
        } else {
          this.watingCount++
        }
      }, 2000);
    },

    /**
     * errorCode 2,4 일때 사용.
     */
    playerReload(){
      this.liveLoadError = true
      this.isLoadedmetadata = false;
      this.onPlayerReloadTimeout();
      this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
    },
    onPlayerReloadTimeout(){
      if(this.reloadInterval === null) {
        this.reloadInterval = setInterval(()=> {
          this.reloadPlayer();
        },2000)
      }
    },


    /**
     * 영상이 재생중일떄
     * @param player
     */
    onPlaying(player) {
      console.log("playerPlaying",player)
    },

    playerReadied(player) {
      console.log("playerReadied",player)
      this.getVideo();
      this.$emit("isReadyPlay",player);

    },

    onPlayerPlay(player) {
      console.log("onPlayerPlay", player)
      this.isLoadedmetadata = true;
      this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
    },

    onPlayerWaiting(player){
      if(this.isVod) return
      console.log("onPlayerWaiting", player)

      // 웨이팅이 6초이상 진행되면 다시연결
      if(this.watingInterval === null && !this.isWaitingRunning) {
        this.isWaitingRunning = true;
        console.log("run reloadWating")
        this.runWaitingInterval()
      }else {
        console.log("this.watingInterval not Null")
      }
    },
    onCanPlay(player) {
      console.log("onCanPlay",player)
      this.init()
      this.isPlayStatus = true;
      this.isLoadedmetadata = true;
      this.liveLoadError = false
      this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
    },
    onPlayerPause(player) {
      console.log("onPlayerPause", player)
      // this.isLoadedmetadata = false;
      this.isPlayStatus = true;
    },
    playerError(){
      let error = this.player.error();
      let time = this.player.currentTime();
      console.log("playerError", error)
      switch (error.code) {
        case 2 : {
          if(this.isVod == false){
            this.playerReload()
          }else{
            this.onPlayerRemove();
          }
          break;
        }
        case 3 : {
          if(this.isVod){
            this.player.load()
            this.player.currentTime(time+1);
            this.player.play()
          } else {

            this.player.load()
            this.player.play()
          }
          break;
        }
        case 4 : { // src가 없는경우
          this.playerReload()
          break;
        }
      }
    },
    onPlayerLoadeddata() {
      console.log("onPlayerLoadeddata")
      if(this.player.error() != null) {
        this.isLoadedmetadata = false;
      } else {
        if(this.isAiMarkShow || this.$router.currentRoute.name == 'vodPlay'){
          if(this.$refs.aiSelect != undefined) {
            this.player.el_.appendChild(this.$refs.aiSelect);
          }
        }
        if(this.$router.currentRoute.name == 'vodPlay' || this.$router.currentRoute.name == 'VOD' || this.$router.currentRoute.name == 'livePlay'){
          this.player.el_.appendChild(this.$refs.vodScreenShot);
        }
        if(this.$router.currentRoute.name == 'livePlay'){
          this.player.el_.appendChild(this.$refs.popupFullScreen);
        }
      }
    },
  },
};
</script>



<style>

.right-bottom-fullscreen {
  position: absolute;
  bottom:10px;
  right: 10px;
  z-index: 10;
  line-height: 28px;
}
.soundBox {
  position: absolute;
  bottom:10px;
  right: 40px;
  z-index: 10;
  line-height: 28px;
}


.speekerSize {
  width: 20px;
}

/*비디오 다운로드*/
.vodDown{
  width: 36px;
  height: 36px;
  text-align: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  padding: 6px;
  color:black;
  border: 1px solid white;
}
.vodDown:hover{
  background: #94d9ef;
}
.video-js .vjs-volume-bar{
  margin: 1.35em 0em 1.35em 0.45em;
}

.vjs-slider-horizontal .vjs-volume-level:before{
  top: -0.3rem !important;
}
.video-js .vjs-play-progress:before{
  top: 15% !important;
  transform: translate(-0%, -40%);
}

.vjs-duration, .vjs-current-time, .vjs-time-divider {
  display: block !important;
}

.vjs-big-play-button > span.vjs-icon-placeholder{
  font-size: 3rem !important;
}

.video-player, .vjs-icon-placeholder, .vjs-volume-bar, .vjs-progress-control,.vjs-control-bar, .vjs-menu-button {
  font-size: 1.2rem !important;
}

.vjs-time-control {
  min-width: 0rem !important;
  display: block !important;
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}
.vjs-fullscreen > #info-live-root > img {
  width: 8rem;
}

.livePlay > img {
  width: 6rem;
}
.livePopMark > img{width: 45px;}

.vjs-picture-in-picture-control {
  display: none !important;
}
</style>
